// colors
$light-gray: #fdf7f2;

html,
body {
  margin: 0;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-gray;
  scroll-behavior: smooth;
}

.jsx-link {
  text-decoration: none;
  font-weight: 700;
  color: #00a0af;
}

.circle-selected {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 3px;
  background: #fff;
  color: #00a0af;
  text-align: center;
  font-size: 12px;
  vertical-align: "text-top";
}

.circle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 3px;
  background: #fff;
  color: #00a0af;
  text-align: center;
  font-size: 12px;
  vertical-align: "text-top";
}

.list-two-column {
  list-style-type: disc;
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  list-style-position: inside;
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-single-column {
  list-style-position: inside;
  list-style: none;
  margin: 0;
  padding: 0;
}

path {
  cursor: pointer;
}

label {
  white-space: nowrap;
}

.jump-link {
  scroll-margin-top: 80px;
}

.conversation-viz {
  display: inline-flex;
  flex-direction: column;
  margin-top: 20px;

  .tooltip {
    position: absolute;
    text-align: left;
    padding: 5px;
    margin-top: 5px;
    margin-left: 5px;
    background: white;
    font-size: 12px;
    border: solid #a9a9a9;
    border-width: 2px;
    border-radius: 5px;
    pointer-events: none;
  }

  .tag-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    margin-bottom: 20px;

    .button-container {
      display: flex;
      flex: 1;
      flex-direction: column;

      .icon {
        margin-right: 0;
        margin-top: 5px;
      }
    }

    button {
      flex: 1;
      // border: none;
      // color: white;
      padding: 8px 0;
      border-radius: 10px;
      margin: 0 5px;
      border-width: 2px;
      border-style: solid;
      font-size: 12px;
      font-weight: bold;

      &.disabled {
        opacity: 0.3;
      }
    }
  }

  .snippet.hasTag {
    fill: #767676;
  }

  .snippet.hover {
    fill: #ffbb00 !important;
  }

  $self-efficacy: #3b899b;
  $impact: #519237;
  $learning-experiences: #327342;
  $thriving-communities: #00a0af;
  $youth-empowerment: #9ac255;
  $values: #355d72;

  @mixin connection($color) {
    border-color: $color;
    color: $color;

    &.active {
      background-color: $color;
      color: white;
    }

    &.line {
      stroke: $color;
    }

    &:not(.active):hover {
      background-color: "white";
    }
  }

  .self-efficacy {
    @include connection($self-efficacy);
  }

  .impact {
    @include connection($impact);
  }

  .learning-experiences {
    @include connection($learning-experiences);
  }

  .thriving-communities {
    @include connection($thriving-communities);
  }

  .youth-empowerment {
    @include connection($youth-empowerment);
  }

  .values {
    @include connection($values);
  }
}
